<template>
  <section>
    <div class="ll-box d-flex">
    <!-- breadcrumb -->
    <div class="breadcrumb-top">
      <b-breadcrumb class="">
        <b-breadcrumb-item :to="{ name: 'dashboard' }">
          <feather-icon
            icon="HomeIcon"
            size="16"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          {{ $t('Master Data') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active >
          {{ $t('Holiday Master') }}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>
    <!-- button -->
      <b-row class="mb-1 justify-content-end">
        <!-- <b-col cols="2" class="d-flex justify-content-end"> -->
          <b-button
            variant="primary"
            class="mr-1"
            v-b-modal.modal-holiday
          >
            Add Holiday
          </b-button>
        <!-- </b-col> -->
      </b-row>
    </div>
    <b-table
      ref="refUserListTable"
      class="position-relative ll-table"
      :items="rows"
      responsive
      :fields="columns"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      :striped="true"
      :hover="true"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @sort-changed="onSortChange"
      :sticky-header="true"
      :busy="isBusy"
      :no-border-collapse="true"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <!-- Column: Actions -->
      <template #cell(action)="row">
        <b-dropdown size="sm" variant="link" no-caret>
          <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"></feather-icon>
          </template>
          <b-dropdown-item href="#" @click="onDetail(row.item)">{{ $t("Edit") }}</b-dropdown-item>
          </b-dropdown>
      </template>
      
    </b-table>

    <div class="d-flex justify-content-between flex-wrap ll-p">
      <div class="d-flex align-items-center mb-0 mr-1">
        <span class="text-nowrap ">
          Showing 1 to
        </span>
        <b-form-select
          v-model="size"
          :options="pageOptions"
          class="mx-1"
          @input="handlePageChange"
        />
        <span class="text-nowrap"> of {{ totalSize }} entries </span>
      </div>
      <div>
        <b-pagination
          v-model="page"
          :total-rows="totalSize"
          :per-page="size"
          first-number
          last-number
          class="mb-0 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          @change="handleChangePage"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </div>
    <!-- upload -->
    <validation-observer ref="add-holiday" v-slot="{ invalid }">
    <b-modal
      id="modal-holiday"
      cancel-variant="outline-secondary"
      ok-title="Sure"
      centered
      title="Add Holiday"
      v-model="show"
      :noEnforceFocus="true" :noCloseOnBackdrop="true"
      @hidden="query={},date=''"
    >
        <div>
            <b-form-group
              class="ll-bform"
              label=""
              label-for="Holiday Name"
            >
              <validation-provider
                #default="{ errors }"
                name="Holiday Name"
                rules="required"
              >
                <label class="ll-boldText">{{ $t('Holiday Name') }}</label>
                <b-form-input
                  v-model="query.name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              class="ll-bform"
              label=""
              label-for="Date"
            >
              <validation-provider
                #default="{ errors }"
                name="Date"
                rules="required"
              >
                <label class="ll-boldText">{{ $t('Date') }}</label>
               <flat-pickr
                  v-model="date"
                  class="form-control ll-fpickr bg-white"
                  :config="{ enableTime: false ,}"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              class="ll-bform"
              label=""
              label-for="Holiday Name in Chinese"
            >
              <validation-provider
                #default="{ errors }"
                name="Holiday Name in Chinese"
                rules="required"
              >
                <label class="ll-boldText">{{ $t('Holiday Name in Chinese') }}</label>
                <b-form-input
                  v-model="query.cn_name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
        </div>
        <template #modal-footer>
            <b-button
            variant="info"
            class="btn"
            @click="show=false;query={},date=''"
        >
            {{ $t(' Cancel') }}
        </b-button>
        <b-button
            variant="primary"
            class="btn"
            :disabled="invalid"
            @click="submit"
        >
            {{ $t(' Submit') }}
        </b-button>
        </template>
    </b-modal>
    </validation-observer>
  </section>
</template>

<script>
import {
  BPagination,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BButton,
  BTooltip,
  BCard,
  BCardBody,
  BRow,
  BCol, 
  BTable,
  BInputGroupPrepend,
  BInputGroup,
  BBreadcrumb,
  BBreadcrumbItem,BDropdown,
    BDropdownItem,
  BBadge,BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import permission from '@core/directives/permission/index.js'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss';
export default {
  components: {
    BPagination,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BButton,
    BTooltip,
    BCard,
    BCardBody,
    BRow,
    BCol,
    vSelect,
    BTable,
    BInputGroupPrepend,
    BInputGroup,
    BBreadcrumb,
    BBreadcrumbItem,
    BBadge,
    BLink,flatPickr,BDropdown,
    BDropdownItem,
    ValidationProvider,ValidationObserver,ToastificationContent
  },
  directives: {
    permission,
  },
  data() {
    return {
      required,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      pageOptions: [50, 100, 200],
      page: 0, // 当前页码
      size: 50, // 每页个数
      totalSize: null, // 总个数
      totalPage: null, // 总页数
      columns: [
        { key: 'name', label: this.$t('Holiday Name'), sortable: true },
        { key: 'date', label: this.$t('Date'), sortable: true },
        { key: 'cn_name', label: this.$t('Holiday Name in Chinese'), sortable: true },
        { key: 'is_active', label: this.$t('Status'), sortable: true },
        { key: 'action', label: this.$t('Action'), class: 'll-action' },
      ],
      rows: [],
      query:{
        is_active:true
      },
      condition: {
        page: 0,
        size: 50,
        sequence: 'desc',
        orderBy: 'id',
      },
      isBusy: true,
      show: false,
      date:''
    }
  },
  computed: {},
  created() {
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 首次查询
    getList() {
      this.$http.post('/config/pickup/holiday/list', this.condition).then(res => {
        const info = res.data.data
        this.rows = info.content
        this.page = info.page
        this.size = info.size
        this.totalSize = info.totalSize
        this.totalPage = info.totalPage
        this.isBusy = false
      }).catch(error => console.log(error))
    },
    // 条件过滤搜索
    filterSearch() {
      this.getList()
    },
    // 排序规则
    onSortChange(params) {
        console.info(params,'params')
      const orderBy = params.sortBy
      const sequence = params.sortDesc
      return params.sortDesc
    },
    searchById() {
      // this.condition.locationid = this.condition.locationid.trim()
      this.condition.page = 0
      this.getList()
    },
    handleChangePage(page) {
      this.condition.page = page-1
      this.getList()
    },
    handlePageChange(active) {
      this.condition.size = active
      this.getList()
    },
    onDetail(item){
        this.show = true
        this.query = item
        this.date = item.date
    },
   submit(){
        this.query.date = this.date
        this.$http.post('/config/pickup/holiday/save', this.query).then(res => {
            if (res.data.code === '200') {
                this.show = false
                this.$toast({
                    component: ToastificationContent,
                    props: {
                    title: 'Save Success',
                    icon: 'CheckIcon',
                    variant: 'success',
                    },
                })
                this.getList()
            }else{
                this.$toast({
                    component: ToastificationContent,
                    props: {
                    title: res.data.message,
                    icon: 'XIcon',
                    variant: 'danger',
                    },
                })
            }
        })
   },
  },
}
</script>
<style scoped>
.ll-fpickr {
  padding-left: 1rem;
  padding-right: 1rem;
}
.ll-btn{
  position: fixed;
  bottom: 10px;
  display: flex;
  justify-content: space-between;
  padding-right: 6rem;
  width: 100%;
}
.ll-btnS{
  margin-right: 20px;
}
.ll-colBox{
  margin: auto;
}
.ll-outline{
  outline: none;
}
.ll-searchBtn div{
  background-color: #ff9f43;
  color: #fff;
  width: 60px;
  display: flex;
  justify-content: center;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.ll-box{
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 10px;
}
.ll-uploadIcon{
  width: 5em;
  height: 5em;
}
.ll-success{
  fill: #28c76f;
}
.ll-fail{
  fill: #ea5455;
}
.ll-uploadText{
  font-weight: bold;
  font-size: 24px;
}
</style>
<style>
.ll-upload .el-upload-dragger{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.ll-upload .el-upload{
  width: 100%;
}
[dir] .vgt-table.bordered th{
  padding: 10px;
  font-size: 12px;
}
table.vgt-table{
  font-size: 12px;
}
[dir=ltr] .b-sidebar.b-sidebar-right > .b-sidebar-header .close{
  margin-left: unset;
  margin-right: unset;
  font-size: 20px;
}
[dir=ltr] .b-sidebar.b-sidebar-right{
  width: 25%;
}
[dir] .page-item.active{
  height: 27px;
}
.table thead th, .table tfoot th {
  text-transform: capitalize;
}
</style>
